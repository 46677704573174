import React, { Fragment } from "react";
import { graphql } from "gatsby";
import PortableText from "react-portable-text";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";

const PrivacyPolicy = ({ data: { sanityPrivacy, sanitySite } }) => {
  return (
    <Fragment>
      <Layout headerData={sanitySite?.header}>
        <div className="container">
          <div className="px-4 py-12 lg:px-0">
            <PortableText
              className="text-base leading-5 prose max-w-none"
              projectId="p5dzjrg7"
              dataset="production"
              content={sanityPrivacy._rawContent}
              serializers={{
                ul: ({ children }) => (
                  <ul className="relative list-none">{children}</ul>
                ),
                li: ({ children }) => (
                  <li className="relative mx-0 my-1 before:content-[''] before:absolute before:top-[6px] before:-left-[20px] before:h-[8px] before:w-[8px] before:rounded-full before:bg-black before:inline-block">
                    {children}
                  </li>
                ),
              }}
            />
          </div>
        </div>
      </Layout>
      <Footer data={sanitySite?.footer} />
    </Fragment>
  );
};

export default PrivacyPolicy;
export const Head = () => <SEO />;
export const query = graphql`
  query {
    sanityPrivacy {
      title
      _rawContent
    }
    sanitySite {
      header {
        links {
          type
          label
          path
          url
          childMenu {
            type
            label
            path
            url
          }
        }
      }
      footer {
        copyright
        topLinks {
          label
          path
        }
        bottomLinks {
          label
          path
        }
        facebook
        linkedIn
        instagram
        medium
        substack
        youtube
        tiktok
      }
    }
  }
`;
